import styled from 'styled-components';

interface IPropsContainer {
  menuIsOpen: boolean;
}

export const Container = styled.div<IPropsContainer>`
  display: flex;
  width: 100%;
  background-color: var(--background);
  align-items: center;
  position: absolute;
  bottom: 0;
  min-height: 80px;

  .button-expandend {
    display: flex;
    width: 100%;
    background-color: var(--background);
    align-items: center;
    cursor: pointer;
    
    img {
      border-radius: 15rem;
      width: 40px;
      height: auto;
      margin: ${props => (props.menuIsOpen ? '0 12px 0 0' : '0')};
    }
    
    div {
      display: ${props => (props.menuIsOpen ? 'flex' : 'none')};
      flex-direction: column;
    
      strong {
        color: var(--text-color);
      }
    
      span {
        font-size: 0.85rem;
      }
    }
  }
`;
