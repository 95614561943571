import styled, { css } from 'styled-components';

interface IPropsContainer {
  menuIsOpen: boolean;
}

const cssPoppoverMenu = () => css`
  .content-collapse {
    position: fixed;
    margin-left: 37px;
    padding: 0.8rem;
    background: var(--white);
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.2);
    visibility: visible;
    max-width: 350px;

    span {
      display: block;
      color: var(--turquoise);
    }
  }
`;

export const Container = styled.div<IPropsContainer>`
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.25rem;
    padding: 0.5rem 0.5rem 0.5rem 0.71rem;
    color: var(--turquoise);
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;

    div {
      display: flex;
      align-items: center;
    }

    span:not(.title-submenu) {
      margin-left: 0.8rem;
      display: ${props => (props.menuIsOpen ? 'flex' : 'none')};
    }

    &:hover {
      background: var(--turquoise);
      color: var(--white);

      ${props => (!props.menuIsOpen ? cssPoppoverMenu() : '')}
    }

    .rotate-90 {
      animation: rotate 0.25s forwards;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(90deg);
      }
      100% {
        transform: rotate(90deg);
      }
    }
  }
`;
