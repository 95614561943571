import {
    createContext,
    useContext,
    useEffect,
    useState,
    ReactNode
  } from 'react';
  import * as auth from '../auth';
  import QRCode from 'qrcode';
  
  // Variáveis globais (no escopo do módulo) para “singleton”
  let globalSocket: WebSocket | null = null;
  let globalHeartbeat: NodeJS.Timer | null = null;
  
  interface IWebSocketContext {
    socket: WebSocket | null;
    sendMessage: (msg: any) => void;
    createInstance: () => void;
    removeInstance: (instanceId: string) => void;
    activeConnections: any[]; // Ajuste o tipo conforme sua estrutura de conexão
  }
  
  const WebSocketContext = createContext<IWebSocketContext>({
    socket: null,
    sendMessage: () => {},
    createInstance: () => {},
    removeInstance: () => {},
    activeConnections: [],
  });
  
  export const WebSocketProvider = ({ children }: { children: ReactNode }) => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [activeConnections, setActiveConnections] = useState<any[]>([]);
    const token = auth.getToken(); // Ex.: obtém o token do localStorage
  
    // Função genérica para enviar mensagem (não precisa de useCallback
    // se você só quer uma referência estável).
    const sendMessage = (msg: any) => {
      if (globalSocket && globalSocket.readyState === WebSocket.OPEN) {
        globalSocket.send(JSON.stringify(msg));
      }
    };
  
    // useEffect que inicializa (ou reutiliza) o WebSocket. Depende só do token.
    useEffect(() => {
      if (!token) return;
  
      // Se o globalSocket não existe ou está em estado fechado, criamos um novo
      if (!globalSocket || globalSocket.readyState > 1) {
        console.log('Criando novo WebSocket');
        const ws = new WebSocket(`wss://ws.annabank.com.br/?token=${token}`);
        globalSocket = ws;
  
        ws.onopen = () => {
          console.log('WebSocket conectado');
          // Assim que conectar, solicita as conexões ativas diretamente.
          ws.send(JSON.stringify({ action: 'list-active-connections' }));
  
          // Inicia o heartbeat (ping a cada 30s)
          globalHeartbeat = setInterval(() => {
            if (ws.readyState === WebSocket.OPEN) {
              ws.send(JSON.stringify({ type: 'ping' }));
            }
          }, 30000);
        };
  
        ws.onmessage = async (event) => {
          try {
            const data = JSON.parse(event.data);
  
            if (data.type === 'pong') {
              console.log('Recebido pong');
            } else if (data.action === 'active-connections') {
              // Converte QR Codes em dataURL
              const processedConnections = await Promise.all(
                data.activeConnections.map(async (conn: any) => {
                  if (conn.qrCode) {
                    const qrCodeDataURL = await QRCode.toDataURL(conn.qrCode);
                    return { ...conn, qrCode: qrCodeDataURL };
                  }
                  return conn;
                })
              );
              setActiveConnections(processedConnections);
  
            } else if (data.action === 'message-sent') {
              console.log('Mensagem enviada:', data);
  
            } else if (data.error) {
              console.error('Erro recebido:', data.error);
            }
          } catch (err) {
            console.error('Erro ao processar mensagem do WebSocket:', err);
          }
        };
  
        ws.onerror = (error) => {
          console.error('Erro no WebSocket:', error);
        };
  
        ws.onclose = () => {
          console.log('WebSocket desconectado');
          // Limpa o interval do heartbeat
          if (globalHeartbeat) {
            clearInterval(globalHeartbeat);
            globalHeartbeat = null;
          }
          // Libera a referência
          globalSocket = null;
        };
  
        setSocket(ws);
      } else {
        // Se já existe um globalSocket válido, apenas define no state
        console.log('Reutilizando WebSocket existente');
        setSocket(globalSocket);
      }
  
      // Se você *realmente* quer fechar o socket ao desmontar este Provider,
      // descomente abaixo. Mas cuidado no modo dev com Strict Mode (monta/desmonta duas vezes).
      return () => {
        // globalSocket?.close();
        // globalSocket = null;
      };
    }, [token]);
  
    // Chamadas auxiliares
    const createInstance = () => {
      sendMessage({ action: 'create-connection' });
    };
  
    const removeInstance = (instanceId: string) => {
      sendMessage({ action: 'delete-connection', instanceId });
    };
  
    return (
      <WebSocketContext.Provider
        value={{
          socket,
          sendMessage,
          createInstance,
          removeInstance,
          activeConnections
        }}
      >
        {children}
      </WebSocketContext.Provider>
    );
  };
  
  export const useWebSocket = () => useContext(WebSocketContext);
  
