import styled from 'styled-components';

interface IContentProps {
  menuIsOpen: boolean;
}

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div<IContentProps>`
  width: ${props =>
    props.menuIsOpen ? 'calc(100% - 280px)' : 'calc(100% - 80px)'};
  transition: all 0.3s;

  .content-outlet {
    margin-top: -115px;
  }

  @media (max-width: 790px) {
    width: 100%;
    transition: all 0s;
  }
`;

export const DesingContainerBlue = styled.div`
  width: 100%;
  height: 195px;
  background: var(--turquoise);
  margin-top: -65px;
`;
