import { useContext, useState } from "react";
import { Container } from "./styles";
import { ContextMenu } from '../../../contexts/Contexts';
import { Accordion, AccordionDetails, AccordionSummary, Skeleton } from "@mui/material";
import { colors } from "../../../styles/global";
import { MdExpandMore, MdLogout } from "react-icons/md";
import { MenuItem } from "../MenuItem";

interface IProps {
    name?: string | null;
    levelAccess?: string | null;
    urlAvatar: string | null;
}


export function Avatar(props: IProps) {
    const menuContext = useContext(ContextMenu);
    const [expanded, setExpanded] = useState(false);

    const handleExpanded = () => setExpanded(!expanded);

    return (
        <Container
            menuIsOpen={!!menuContext.menuIsOpen}
        >

            <Accordion
                disableGutters
                expanded={menuContext.menuIsOpen === true && expanded}
                sx={{ background: colors.background, border: 0, boxShadow: 'none', width: '100%', padding: '0 20px 0 20px' }}
            >
                <AccordionSummary
                    expandIcon={menuContext.menuIsOpen === true ? <MdExpandMore size={20} /> : null}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ padding: 0, background: colors.background, border: 0, boxShadow: 0 }}
                    onClick={handleExpanded}
                >
                    <div className="button-expandend">
                        {
                            props.urlAvatar ? (
                                <img src={props.urlAvatar} alt="profile" />
                            ) : (
                                <Skeleton sx={{ margin: menuContext.menuIsOpen ? '0 12px 0 0' : '0' }} variant="circular" width={40} height={40} />
                            )
                        }
                        <div>
                            {
                                props.name ?
                                    <strong>{props.name}</strong>
                                    :
                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: 120 }} />
                            }

                            {
                                props.levelAccess ?
                                    <span>{props.levelAccess}</span>
                                    :
                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: 75 }} />
                            }
                        </div>
                    </div>

                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                    {
                        menuContext.menuIsOpen === true && expanded && (

                            <MenuItem path="/logout" Icon={MdLogout} title="Sair" />
                        )
                    }
                </AccordionDetails>
            </Accordion>
        </Container >
    )
}