import styled from 'styled-components';

export const widthMenuOpen = '280px';
export const widthMenuClose = '80px';

interface IPropsMenu {
  open: boolean;
}

export const Container = styled.div<IPropsMenu>`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background: var(--white);
  min-width: ${props => (props.open ? widthMenuOpen : widthMenuClose)};
  max-width: ${props => (props.open ? widthMenuOpen : widthMenuClose)};
  height: 100vh;
  /* padding: 16px 16px 0px 16px; */
  
  /* border-radius: 10px; */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: ${props => (props.open ? '0' : '10px')};
  border-bottom-left-radius: ${props => (props.open ? '0' : '10px')};
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
  color: var(--text-light);
  overflow-x: auto;
  transition: all 0.3s;
  z-index: 50;

  a {
    text-decoration: none !important;
  }

  img {
    width: 50px;
    height: auto;
    margin: 1rem 0;
  }

  #container-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 13px; */
    padding: ${props => (props.open ? '16px 16px 0 29px' : '16px 16px 0 24px')};

    #content-img-logo {
      img {
        height: ${props => (props.open ? '27px' : '26px')};
        width: ${props => (props.open ? '160px' : '36px')};
        margin: 0;
      }

      span {
        display: ${props => (props.open ? 'inline' : 'none')};
        color: #125da1;
        font-size: 18px;
        transition: all 0.25s;
        margin-left: 2px;
        position: absolute;
        margin-top: 12px;

        strong {
          font-weight: 700;
        }
      }
    }

    button {
      cursor: pointer;
      background: var(--white);
      border: 0;
      padding: 0.4rem;
      border-radius: 0.25rem;
      display: none;

      &:hover {
        filter: brightness(0.95);
      }
    }
  }

  @media (max-width: 950px) {
    width: ${props => (props.open ? widthMenuOpen : widthMenuClose)};
    min-width: ${widthMenuClose};
  }

  @media (max-width: 790px) {
    position: fixed;
    width: ${props => (props.open ? widthMenuOpen : 0)};
    min-width: 0;
    z-index: 100;

    #container-logo button {
      display: block !important;
    }
  }
`;

export const BackDrop = styled.div<IPropsMenu>`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;

  @media (max-width: 790px) {
    display: ${props => (props.open ? 'block' : 'none')};
  }
`;
