import { useSnackbar } from "notistack";
import { AppContent } from "../../../../components/AppContent";
import { Breadcrumps } from "../../../../components/Breadcrumbs";
import Grid from "@mui/material/Grid";
import { RiInstanceLine } from "react-icons/ri";
import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import { useWebSocket } from "../../../../contexts/WebSocketContext";

export function Instances() {
  const { enqueueSnackbar } = useSnackbar();

  const { createInstance, removeInstance, activeConnections } = useWebSocket();

  const [cardHeight, setCardHeight] = useState(calculateCardHeight());

  function calculateCardHeight() {
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    return windowHeight - 165;
  }

  useEffect(() => {
    function handleResize() {
      setCardHeight(calculateCardHeight());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAddConnection = () => {
    createInstance();
    enqueueSnackbar("Criando nova conexão...", { variant: "info" });
  };

  const handleRemoveConnection = (instanceId: string) => {
    removeInstance(instanceId);
    enqueueSnackbar(`Removendo conexão: ${instanceId}`, { variant: "warning" });
  };

  return (
    <AppContent>
      <Breadcrumps
        Icon={RiInstanceLine}
        title="Instâncias"
        links={[
          { name: "WhatsApp", href: "#" },
          { name: "Instâncias", href: "/app/whatsapp/instances" },
        ]}
      />

      <Grid container justifyContent="center" marginTop={-4} padding={1}>
        <Grid item xs={12}>
          <Card
            sx={{
              p: 2,
              mt: 3,
              textAlign: "center",
              height: `${cardHeight}px`,
              overflowY: "auto",
            }}
          >
            <Grid container spacing={2}>
              {/* Card de "Adicionar Conexão" */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                  sx={{
                    height: 380,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleAddConnection}
                >
                  <Typography variant="h6" sx={{ p: 2 }}>
                    + Adicionar Conexão
                  </Typography>
                </Card>
              </Grid>

              {/* Cards das conexões já existentes */}
              {activeConnections.map((conn: any) => {
                const isConnected = conn.status === "connected";
                const hasQr = Boolean(conn.qrCode);

                return (
                  <Grid key={conn.instanceId} item xs={12} sm={6} md={4} lg={3}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: 380,
                      }}
                    >
                      <CardHeader
                        title="Status"
                        subheader={conn.status}
                      />
                      <CardContent sx={{ flex: 1, overflow: "auto" }}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          height="100%"
                        >
                          {hasQr ? (
                            <img
                              src={conn.qrCode}
                              alt="QR Code"
                              style={{ maxWidth: 200, maxHeight: 200 }}
                            />
                          ) : (
                            <>
                              {isConnected && (<>
                                <Avatar
                                  src={conn.profilePicture || ""}
                                  alt={conn.me?.name || "Perfil"}
                                  sx={{ height: 160, width: 160 }}
                                />
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  sx={{ mt: 1 }} // margin-top: 1
                                >
                                  {conn.me?.name || "Name"}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  {conn.number || "Number"}
                                </Typography>
                              </>)}
                            </>)}
                        </Box>
                      </CardContent>


                      <CardActions
                        sx={{
                          justifyContent: "flex-end",
                          paddingX: 2,
                          paddingBottom: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleRemoveConnection(conn.instanceId)}
                        >
                          Remover
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </AppContent>
  );
}
