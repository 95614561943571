import { HTMLAttributes } from "react"
import { Link } from "react-router-dom";
import { ClockLoader, HashLoader, PacmanLoader, PulseLoader, ScaleLoader } from "react-spinners";
import { colors } from "../../styles/global";
import { ContainerButton, ContainerA } from "./style"

interface IProps extends HTMLAttributes<HTMLButtonElement> {
    color?: 'turquoise' | 'primary-light' | 'turquoise-light' | 'default' | 'borderless' | 'transparent';
    children?: any,
    type?: 'button' | 'submit' | 'reset' | 'link';
    to?: string;
    borderRounded?: boolean;
    disabled?: boolean;
    load?: boolean;
    target?: "_blank" | "_parent" | '_self' | "top";
    typeLoad?: 'pulse' | 'scale' | 'hash' | 'pacman' | 'clock';
}

const getElementLoad = (type: any, color?: string) => {

    switch (color) {
        case 'turquoise':
            color = colors.white;
            break;
        case 'default':
            color = colors.turquoise;
            break;
        case 'turquoise-light':
            color = colors.white;
            break;
        case 'primary-light':
            color = colors.turquoise;
            break;
        case 'borderless':
            color = colors.turquoise;
            break;
        case 'transparent':
            color = colors.turquoise;
            break;
        case 'red':
            color = colors.red;
            break;
        default:
            color = colors.turquoise;
            break;
    }

    switch (type) {
        case 'pulse':
            return <div style={{ marginLeft: 4 }}><PulseLoader color={color} size={7} /></div>;
        case 'scale':
            return <div style={{ marginLeft: 4 }}><ScaleLoader color={color} height={12} width={2} /></div>;
        case 'hash':
            return <div style={{ marginLeft: 4 }}><HashLoader color={color} size={18} /></div>;
        case 'pacman':
            return <div style={{ marginLeft: 4 }}><PacmanLoader color={color} size={7} /></div>;
        case 'clock':
            return <div style={{ marginLeft: 4 }}><ClockLoader color={color} size={17} /></div>
        default:
            return '';
    }
}


export function Button(props: IProps) {
    const loading = props.load;

    if (props.type === 'link') {
        return (
            <ContainerA color={props.color} className={props.className} style={props.style}>
                <Link to={props.to as string} target={props.target}>
                    {props.children}
                </Link>
            </ContainerA>
        )
    } else {
        return (
            <ContainerButton
                className={props.className}
                type={props.type ?? 'button'}
                borderRounded={props.borderRounded}
                color={props.color}
                onClick={props.onClick}
                disabled={loading || props.disabled}
                style={props.style}>
                {props.children} {loading && (getElementLoad(props.typeLoad, props.color))}
            </ContainerButton>
        )
    }
}