import { useSnackbar } from "notistack";
import { AppContent } from "../../../../components/AppContent";
import { Breadcrumps } from "../../../../components/Breadcrumbs";
import Grid from "@mui/material/Grid2";
import { TiMessages } from "react-icons/ti";
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { MdSearch } from "react-icons/md";
import { FilterContent } from "../../../../components/FilterContent";
import { DateRangePicker } from "rsuite";
import { LOCALE_DATEPICKER } from "../../../../constants";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import * as handleRequestError from "../../../../utils/handleRequestError";
import * as configSnackbar from "../../../../utils/configDefaultSnackbar";
import * as apiInterpays from "../../../../services/apiInterpays";

export function Messages() {
  const { enqueueSnackbar } = useSnackbar();
  const [cardHeight, setCardHeight] = useState(calculateCardHeight());
  const [showFilters, setShowFilters] = useState(false);

  // Dois estados para os intervalos de data
  const [timestampRange, setTimestampRange] = useState<[Date, Date]>([new Date(), new Date()]);
  const [sentAtRange, setSentAtRange] = useState<[Date, Date]>([new Date(), new Date()]);

  const [data, setData] = useState<any[]>([]);
  const [searching, setSearching] = useState(false);

  function calculateCardHeight() {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return windowHeight - 165;
  }

  useEffect(() => {
    const handleResize = () => setCardHeight(calculateCardHeight());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleToggleFilters = () => setShowFilters((prev) => !prev);

  const handleSearch = useCallback(async () => {
    setSearching(true);
    try {
      const queryParams = new URLSearchParams();

      // Adiciona os parâmetros para timestamp
      if (timestampRange && timestampRange[0] && timestampRange[1]) {
        queryParams.append("timestampFrom", timestampRange[0].getTime().toString());
        queryParams.append("timestampTo", timestampRange[1].getTime().toString());
      }

      // Adiciona os parâmetros para sentAt
      if (sentAtRange && sentAtRange[0] && sentAtRange[1]) {
        queryParams.append("sentAtFrom", sentAtRange[0].getTime().toString());
        queryParams.append("sentAtTo", sentAtRange[1].getTime().toString());
      }

      // Se desejar filtrar pelo campo "sent", pode usar:
      // queryParams.append("sent", "true");

      const response = await apiInterpays.API.get(`/whatsapp/messages?${queryParams.toString()}`);
      setData(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          handleRequestError.handleError(error.response, enqueueSnackbar);
        } else {
          enqueueSnackbar("Ocorreu um erro inesperado", configSnackbar.error);
        }
      } else {
        enqueueSnackbar("Ocorreu um erro inesperado", configSnackbar.error);
      }
    } finally {
      setSearching(false);
    }
  }, [timestampRange, sentAtRange, enqueueSnackbar]);

  return (
    <AppContent>
      <Breadcrumps
        Icon={TiMessages}
        title="Mensagens"
        links={[
          { name: "WhatsApp", href: "#" },
          { name: "Mensagens", href: "/app/whatsapp/messages" },
        ]}
        onClickButtonFilter={handleToggleFilters}
      />
      <FilterContent open={showFilters} onClose={handleToggleFilters}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={handleToggleFilters} size="large">
            <CloseIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Box>
        {/* DateRangePicker para timestamp */}
        <Typography variant="body1" sx={{ mt: 1 }}>Intervalo de Timestamp</Typography>
        <DateRangePicker
          defaultValue={timestampRange}
          showOneCalendar
          size="lg"
          style={{ width: "100%" }}
          menuStyle={{ zIndex: 200 }}
          placement="bottomEnd"
          onChange={(value) => setTimestampRange(value as [Date, Date])}
          placeholder="Selecione o período do Timestamp"
          locale={LOCALE_DATEPICKER}
        />
        {/* DateRangePicker para sentAt */}
        <Typography variant="body1" sx={{ mt: 2 }}>Intervalo de SentAt</Typography>
        <DateRangePicker
          defaultValue={sentAtRange}
          showOneCalendar
          size="lg"
          style={{ width: "100%" }}
          menuStyle={{ zIndex: 200 }}
          placement="bottomEnd"
          onChange={(value) => setSentAtRange(value as [Date, Date])}
          placeholder="Selecione o período de SentAt"
          locale={LOCALE_DATEPICKER}
        />
        <Box mt={2} width="100%">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            fullWidth
            disabled={searching}
            startIcon={searching ? <CircularProgress size={20} color="inherit" /> : <MdSearch size={20} />}
            sx={{
              backgroundColor: "#0e8ba4",
              color: "#fff",
              "&:hover": { backgroundColor: "#0d7e8b" },
              mt: 2,
            }}
          >
            Filtrar
          </Button>
        </Box>
      </FilterContent>
      <Grid container justifyContent="center" mt={-4} p={1}>
        <Grid size={{ xs: 12 }}>
          <Card sx={{ p: 2, mt: 3, height: `${cardHeight}px`, overflowY: "auto" }}>
            {searching ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>External ID</TableCell>
                      <TableCell>Channel</TableCell>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell>Sent</TableCell>
                      <TableCell>Sent At</TableCell>
                      <TableCell>Delivery Status</TableCell>
                      <TableCell>Send Error</TableCell>
                      <TableCell>Timestamp</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Typography align="center">Nenhuma mensagem encontrada.</Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      data.map((msg) => (
                        <TableRow key={msg._id}>
                          <TableCell>{msg._id}</TableCell>
                          <TableCell>{msg.externalId}</TableCell>
                          <TableCell>{msg.channel}</TableCell>
                          <TableCell>{msg.from}</TableCell>
                          <TableCell>{msg.to}</TableCell>
                          <TableCell>{msg.sent ? "Sim" : "Não"}</TableCell>
                          <TableCell>{msg.sentAt ? new Date(msg.sentAt).toLocaleString() : "-"}</TableCell>
                          <TableCell>{msg.deliveryStatus || "-"}</TableCell>
                          <TableCell>{msg.sendError || "-"}</TableCell>
                          <TableCell>{new Date(msg.timestamp).toLocaleString()}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Card>
        </Grid>
      </Grid>
    </AppContent>
  );
}
